.view-switcher {
    display: inline-flex;
    border: 1px solid var(--switcher-grey);
    border-radius: 5px;
    font-family: var(--rubik-medium);
    color: rgb(149, 157, 167);

    &-button {
        border-radius: 3px;
        &:focus {
            background-color: var(--state-lightblue-default);
            color: white;
            &.selected {
                color: white;
            }
        }
        &:hover {
            background-color: var(--state-lightblue-default);
            color: white;
            &.selected {
                color: white;
            }
        }
        &.selected {
            color: var(--primary-black);
        }
        display: inline-block;
        padding: 6px 10px;
        text-transform: capitalize;
        border-right: 1px solid var(--switcher-grey);
        width: auto;
        height: auto;
        font-size: 12px;
        line-height: 16px;

        &:last-child {
            border-right: none;
        }
    }
}