.outcome {
    margin: 12px 8px;

    &__dropdowns {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
    
        .sa-dropdown-wrapper {
            flex-grow: 1; 
        }

        .sa-dropdown-wrapper:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__inputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        &-target {
            flex-grow: 1;
            margin-right: 16px;
        }
        &-comment {
            flex-grow: 3;
            textarea {
                height: 40px;
            }
        }
    }

    &-button {
        width: 176px;
    }
}