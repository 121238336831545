.call-overview-wrapper {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 16px;

    &__title {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        padding-bottom: 4px;
    }

    &__main {
        display: flex;
        justify-content: space-between;

        &__info {
            display: flex;
            align-items: center;

            .phone-number {
                font-size: 14px;
                font-weight: bold;
                margin-right: 8px;
            }
        }

        &__buttons {
            .button {
                width: 92px;
                height: 24px;
                margin-left: 8px;
            }
        }
    }

    &__timer {
        line-height: 20px;
        font-weight: 500;
    }

    &__status {
        color: white;
        width: 80px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        height: 20px;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
    }

    .orange {
        background-color: #e17500;
    }
    .grey {
        background-color: #686868;
    }
    .green {
        background-color: #009f84;
    }
    .blue {
        background-color: #458DC9;
    }

    .error {
        color: red;
    }
}
